html, #root, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background: #2C3E50;*/
  /*background: #35485a;*/
  background: #000;
  font-size: 18px;
}

@font-face {
    font-family: JCBEuro;
    src: url(/fonts/jcber-webfont.eot) format("eot");
    src: url(/fonts/jcber-webfont.eot?#iefix) format("embedded-opentype"), url(/fonts/jcber-webfont.svg#JCBEuroRomanRegular) format("svg"), url(/fonts/jcber-webfont.woff) format("woff"), url(/fonts/jcber-webfont.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}
@font-face{
    font-family:JCBEuro-Bold;
    src:url(/fonts/jcbeb-webfont.eot) format("eot");
    src:url(/fonts/jcbeb-webfont.eot?#iefix) format("embedded-opentype"),url(/fonts/jcbeb-webfont.woff) format("woff"),url(/fonts/jcbeb-webfont.ttf) format("truetype"),url(/fonts/jcbeb-webfont.svg#JCBEuroBoldRegular) format("svg");
    font-weight:700;
    font-style:normal;
    font-stretch:normal;
}
@font-face{
    font-family:JCBEuro-Italic;
    src:url(/fonts/jcbei-webfont.eot) format("eot");
    src:url(/fonts/jcbei-webfont.eot?#iefix) format("embedded-opentype"),url(/fonts/jcbei-webfont.woff) format("woff"),url(/fonts/jcbei-webfont.ttf) format("truetype"),url(/fonts/jcbei-webfont.svg#JCBEuroItalicRegular) format("svg");
    font-weight:400;
    font-style:italic;
    font-stretch:normal;
}

input {
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.tippy-popper {
    top: -2px!important;
}
